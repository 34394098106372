@import "./variables.scss";

.wrapper {
  position: fixed;
  background-color: #00000090;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal {
  width: 50%;
  background-color: lighten($background-default, 5%);
  border-radius: 5px;
  padding: 20px;
}

.close {
  font-size: 25px;
  float: right;
}
