@import "./reset.scss";
@import "./variables.scss";

body,
html {
  background-color: $background-default;
  color: $foreground-default;
  font-family: sans-serif;
}

.light {
  background-color: $background-default;
  color: $foreground-default;
}

#root {
  height: 100vh;
}

a {
  color: #fff;
}
