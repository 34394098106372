@import "./variables.scss";

.main {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  justify-content: center;
}

.main > .content {
  flex: 1;
  width: 60%;
}

.footer {
  width: 100%;
  background-color: lighten($background-default, 5%);
}

.footer > .content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar {
  background-color: $background-light;
  line-height: 60px;
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.navbar > .left {
  flex: 1;
}

.navbar > ul {
  list-style-type: none;
}

.navbar > ul > li {
  display: inline-block;
}

.navbar .navbarItem {
  line-height: 60px;
  height: 60px;
  color: $foreground-default;
  padding-left: 15px;
  padding-right: 15px;
}

.navbar .navbarItem:hover {
  background-color: darken($background-light, 5%);
  padding-left: 15px;
  padding-right: 15px;
}
